import React from 'react';
import { Avatar, Card, CardContent, Container, Typography } from '@mui/material';
import Slider from 'react-slick';
import testimonialImg1 from '../../assets/images/testimonial/1.png';
import testimonialImg2 from '../../assets/images/testimonial/2.png';
import testimonialImg3 from '../../assets/images/testimonial/6.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './style/Testimonial.css'; // Import the CSS file

const testimonials = [
  {
    text: "In the above testimonial, a customer named Jeanine shares her experience with Briogeo’s products. While the post is scattered with too many product mentions, it takes full advantage of its real estate by allowing the writer to tell",
    author: 'Guy Hawkins',
    role: 'Trade Boss',
    image: testimonialImg1
  },
  {
    text: "The above testimonial is about Martha Chumo, who taught herself to code in one summer. This testimonial example works because it allows prospective customers to see themselves in Codeacademy’s current customer base.",
    author: 'Belal Hossain',
    role: 'Trade Genius',
    image: testimonialImg2
  },
  {
    text: "This testimonial shows the journey of a user who benefited from our services, highlighting the key points and making a strong case for potential customers.",
    author: 'John Doe',
    role: 'Trading Expert',
    image: testimonialImg3
  }
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false
};

const Testimonial = () => {
  return (
    <Container style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <Typography variant="h4" component="h2" align="center" style={{ marginBottom: '20px'}}>
        <h2 className="mb-10 mt-minus-5"><span style={{ color: "#0a4fd5"  }}>Connect With</span> Our Clients</h2>
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        We love connecting with our clients to hear about their experiences and how we can improve.
      </Typography>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <Card style={{ padding: '20px', margin: '0 10px' }}>
              <CardContent style={{ height: '200px' }}>
                <Typography variant="body1" style={{ marginBottom: '20px', fontStyle: 'italic' }}>
                  {testimonial.text}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar src={testimonial.image} style={{ width: '60px', height: '60px' }} />
                  <div style={{ marginLeft: '10px' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {testimonial.author}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {testimonial.role}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default Testimonial;
