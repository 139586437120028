import React from 'react';
import { useMediaQuery } from '@mui/material';
import LightDark from '../components/Home/LightDark';
import Header from '../components/Home/Header';
import Banner from '../components/Home/Banner';
import CounterPadding from '../components/Home/CounterPadding';
import About from '../components/Home/About';
import Services from '../components/Home/Services';
import OurPlans from '../components/ForexSignal/OurPlans';
import BlogSection from '../components/Home/BlogSection';
import Pricing from '../components/Home/Pricing';
import Testimonial from '../components/Home/Testimonial';
import Faq from '../components/Home/Faq';
import Subscribe from '../components/Home/Subscribe';
import Footer from '../components/Footer';
import MobileHeader from '../components/MobileHeader';
import ForexSignals from '../components/Home/ForexSignals';

const Home = () => {
  // Use useMediaQuery to detect if the screen size is mobile
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <div>
      {/* <LightDark /> */}
      {!isMobile && <Header />} {/* Render Header only on desktop view */}
      {isMobile && <MobileHeader />} {/* Render MobileHeader only on mobile view */}
      <Banner />
      <CounterPadding />
      <ForexSignals />
      <About />
      <Services />
      {isMobile &&<OurPlans />}
      {!isMobile && <Pricing />}
      <BlogSection />
      <Testimonial />
      <Faq />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Home;
