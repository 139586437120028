import React from "react";
import bgImage from "../../assets/images/pricing/bg.png";
import checkIcon from "../../assets/images/icon/check.svg";
import shape1 from "../../assets/images/icon/shape/1.png";
import shape3 from "../../assets/images/icon/shape/3.png";

const Pricing = () => {
  return (
    <div>
      <section
        className="pricing padding-top padding-bottom bg--cover"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">
            Unlock Our <span>premium </span>plan
          </h2>
          <p>
            Curious about Crypto signals, how they work, and why they're
            essential for your long-term investment goals? Look no further!
            Check out our comprehensive guide on our free Crypto signals service.
            Plus, with our 30-day money-back guarantee, there's no risk in
            giving us a try. You won't regret it! After your purchase, you'll
            receive an email with a link to our Telegram bot. Simply provide the
            email used for the purchase, and the bot will grant you access to
            the VIP channel. Start benefiting from expert insights today
          </p>
        </div>
        <div className="container">
          <div className="pricing__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  dataaos="fade-right"
                  dataaosduration="1000"
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Basic</h6>
                        <h3 className="mb-25">
                          $99/<span>Monthly</span>
                        </h3>
                      </div>

                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Weekly online meeting
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                        </ul>
                      </div>

                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="signup.html"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  dataaos="fade-up"
                  dataaosduration="1000"
                >
                  <div className="pricing__item-inner active">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Standard</h6>
                        <h3 className="mb-25">
                          $149/<span>Monthly</span>
                        </h3>
                      </div>

                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Weekly online meeting
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Business analysis
                          </li>
                        </ul>
                      </div>

                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="signup.html"
                          className="trk-btn trk-btn--outline active"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  dataaos="fade-left"
                  dataaosduration="1000"
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Premium</h6>
                        <h3 className="mb-25">
                          $199/<span>Monthly</span>
                        </h3>
                      </div>

                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            With all standard features
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Unlimited learning access
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src={checkIcon}
                                alt="check"
                                className="dark"
                              />
                            </span>
                            Personal training
                          </li>
                        </ul>
                      </div>

                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="signup.html"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing__shape">
          <span className="pricing__shape-item pricing__shape-item--5">
            <img src={shape3} alt="shape-icon" />
          </span>
          <span className="pricing__shape-item pricing__shape-item--6">
            <img src={shape1} alt="shape-icon" />
          </span>
        </div>
      </section>
    </div>
  );
};

export default Pricing;
