import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import blog1 from "../../assets/images/blog/1.png";
import blog2 from "../../assets/images/blog/2.png";
import blog3 from "../../assets/images/blog/3.png";
import author1 from "../../assets/images/blog/author/1.png";
import author2 from "../../assets/images/blog/author/2.png";
import author3 from "../../assets/images/blog/author/3.png";

const articles = [
  {
    category: "Trading Market",
    title: "Hedge Funds Work?",
    description:
      "To cater to your individual trading preferences, we offer a variety of order types, including market.",
    author: "Abhivibha Kanmani",
    date: "30/5/2024",
    image: blog1,
    authorImage: author1,
  },
  {
    category: "Investment",
    title: "Options Trading Business?",
    description:
      "Security is our top priority, and we employ robust measures to ensure the safety of your funds.",
    author: "Hulya Aydin",
    date: "12/07/2024",
    image: blog2,
    authorImage: author2,
  },
  {
    category: "Forex Trading",
    title: "Swing Trading Definition",
    description:
      "Our platform is not only about trading—it's also a hub for knowledge and learning. We provide resources.",
    author: "Vasha Gueye",
    date: "20/6/2024",
    image: blog3,
    authorImage: author3,
  },
];

const BlogSection = () => {
  return (
    <Container>
      <Typography variant="h4" component="h2" style={{ marginBottom: "20px"}}>
        <h2 className="mb-10 mt-minus-5"><span style={{ color: "#0a4fd5"  }}>Articles</span> For Pro Traders</h2>
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Hey there pro traders, check out these articles with tips to take your
        trading game to the next level!
      </Typography>
      <Grid container spacing={4}>
        {articles.map((article, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card style={{ height: "100%" }}>
              <CardMedia
                component="img"
                style={{ padding: "10px", height: "180px", objectFit: "cover" }}
                image={article.image}
                alt={article.title}
              />
              <CardContent style={{ paddingBottom: "16px" }}>
                <Typography variant="caption" color="textSecondary">
                  {article.category}
                </Typography>
                <Typography variant="h6" component="h3">
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {article.description}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Avatar src={article.authorImage} />
                  <div style={{ marginLeft: "10px" }}>
                    <Typography variant="body2">{article.author}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {article.date}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button
          variant="contained"
          style={{
            marginRight: "10px",
            backgroundColor: "#00d094",
            color: "#000000",
            height: "60px",
            width: "120px",
            fontWeight: "bold",
          }}
        >
          View More
        </Button>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <IconButton>
          <ArrowBack />
        </IconButton>
        <IconButton>
          <ArrowForward />
        </IconButton>
      </div>
    </Container>
  );
};

export default BlogSection;
