// src/components/ForexSignals.js

import React from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TelegramIcon from "@mui/icons-material/Telegram";
// import './style/ForexSignals.css';

const ForexSignals = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px",
        // paddingTop: '30px',
        paddingBottom: "40px",
      }}
    >
      <Box className="container">
        <Typography
          variant="h4"
          className="title"
          style={{ marginBottom: "20px" }}
        >
          <div className="section-header section-header--max50">
            <h2 className="mb-10 mt-minus-5">
              <span className="style2">
                Begin Your Journey with Us {" "}
              </span>
              Start Receiving Free Forex Signals and Expert Crypto Tips!
            </h2>
          </div>
        </Typography>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            //  style={{ backgroundColor: "yellow" }}
          >
            <Box className="features">
              <Box
                className="feature"
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Box
                  sx={{
                    width: 60,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 2,
                  }}
                >
                  <NotificationsActiveIcon className="icon" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    className="feature-title"
                    align="left"
                  >
                    Notifications
                  </Typography>
                  <Typography variant="body1" align="left">
                    Immediate alerts to Telegram from Learn to Trade
                  </Typography>
                </Box>
              </Box>
              <Box
                className="feature"
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Box
                  sx={{
                    width: 55,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 2,
                  }}
                >
                  <LightbulbIcon className="icon" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    className="feature-title"
                    align="left"
                  >
                    Forex Experts
                  </Typography>
                  <Typography variant="body1" align="left">
                    Daily Technical Analysis and Trading Tips
                  </Typography>
                </Box>
              </Box>
              <Box
                className="feature"
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <Box
                  sx={{
                    width: 55,
                    height: 50,
                    borderRadius: "50%",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 2,
                  }}
                >
                  <TrendingUpIcon className="icon" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    className="feature-title"
                    align="left"
                  >
                    Market Leading
                  </Typography>
                  <Typography variant="body1" align="left">
                    Up to 5 Accurate, Profitable Signals Per Day
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "blue",
            }}
          >
            <Box className="details">
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className="check-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Up to 5 Accurate, Profitable Signals per Day" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className="check-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Easy access to Forex Signals & Crypto Signals" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className="check-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Daily Technical Analysis and Trading Tips" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className="check-icon" />
                  </ListItemIcon>
                  <ListItemText primary="A community of Over 70,000 active traders" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon className="check-icon" />
                  </ListItemIcon>
                  <ListItemText primary="Real-time alerts, all via Telegram" />
                </ListItem>
              </List>
              <Box
                className="buttons"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<TelegramIcon />}
                  className="signal-button"
                  sx={{ mb: 2, width: "60%" }}
                >
                  Free Forex Signals
                </Button>
                <Button
                  variant="contained"
                  startIcon={<TelegramIcon />}
                  className="signal-button"
                  sx={{ width: "60%" }}
                >
                  Free Crypto Signals
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ForexSignals;
