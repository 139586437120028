import React from "react";
import { useMediaQuery } from '@mui/material';
import Header from "../components/ForexSignal/Header";
import MobileHeader from "../components/MobileHeader";
import PageHeader from "../components/ForexSignal/PageHeader";
import OurPlans from "../components/ForexSignal/OurPlans";
import Testimonial from "../components/Home/Testimonial";
import Footer from "../components/Footer";
import Subscribe from "../components/Home/Subscribe";
import PartnerCarousel from "../components/ForexSignal/PartnerCarousel";
import Pricing from "../components/Home/Pricing";
import ServiceInfo from "../components/ForexSignal/ServiceInfo";
import BrokerSection from "../components/ForexSignal/BrokerSection";
import ProfessionalTradersSection from "../components/ForexSignal/ProfessionalTradersSection";

function ForexSignal() {
  // Use useMediaQuery to detect if the screen size is mobile
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <div>
      {!isMobile && <Header />} {/* Render Header only on desktop view */}
      {isMobile && <MobileHeader />}{" "}
      {/* Render MobileHeader only on mobile view */}
      <PageHeader />
      <PartnerCarousel />
      <ServiceInfo />
      {isMobile && <OurPlans /> }
      {!isMobile && <Pricing /> }
      <BrokerSection />
      <Testimonial />
      <ProfessionalTradersSection />
      <Subscribe />
      <Footer />
    </div>
  );
}

export default ForexSignal;
