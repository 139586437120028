import React, { useState } from "react";
import headerImage1 from "../../assets/images/header/1.png";
import headerImage2 from "../../assets/images/header/2.png";
import { useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import { Diversity1 } from "@mui/icons-material";

function PageHeader() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <div>
      <section
        className="page-header bg--cover"
        style={{ backgroundImage: `url(${headerImage1})` }}
      >
        <div className="container">
          <div
            className="page-header__content"
            dataAos="fade-right"
            dataAosDuration="1000"
          >
            {!isMobile && (
              <nav
                style={{ "--bs-breadcrumb-divider": "'/'" }}
                ariaLabel="breadcrumb"
              >
                <ol className="breadcrumb mb-0">
                  <li
                    className="breadcrumb-item"
                    style={{ marginBottom: "15px" }}
                  >
                    <a href="#">Best Crypto Signals</a>
                  </li>
                </ol>
                <h2 style={{ textAlign: "left", marginBottom: "15px" }}>
                  Crypto Signals
                </h2>
              </nav>
            )}
            {isMobile && (
              <nav
                style={{ "--bs-breadcrumb-divider": "'/'" }}
                ariaLabel="breadcrumb"
              >
                <ol
                  className="breadcrumb mb-0"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <li
                    className="breadcrumb-item"
                    style={{ textAlign: "center", marginBottom: "15px" }}
                  >
                    Best Forex Signals
                  </li>
                </ol>
                <div>
                  <h3 style={{ textAlign: "center", color: "#ffffff" }}>
                    Subscribe To Our Daily Crypto Signals
                  </h3>
                  <div className="header-btn" style={{ marginTop: "20px" }}>
                    <a
                      href="signup.html"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>GET VIP CRYPTO SIGNALS</span>
                    </a>
                  </div>
                </div>
              </nav>
            )}
            <div
              style={{
                width: "600px",
                textAlign: "left",
              }}
            >
              {!isMobile && (
                <ol>
                  <li
                    style={{
                      color: "#ffffff",
                      textAlign: "left",
                      fontSize: "18px",
                      marginTop: "15px",
                    }}
                  >
                    ProTradesInsight's live crypto signals are trusted by over
                    5,000 traders. Our reliable signals are provided by expert
                    traders with over 15 years of combined market experience,
                    helping our members earn thousands of pips in profit each
                    month. Purchase our daily crypto signals today, or check out
                    our free Telegram crypto signals
                  </li>
                </ol>
              )}
              {!isMobile && (
                <div className="header-btn" style={{ marginTop: "30px" }}>
                  <a
                    href="signup.html"
                    className="trk-btn trk-btn--border trk-btn--primary"
                  >
                    <span>GET VIP CRYPTO SIGNALS</span>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="page-header__shape">
            <span className="page-header__shape-item page-header__shape-item--1">
              <img src={headerImage2} alt="shape-icon" />
            </span>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PageHeader;
