import React from "react";
import moon from "../../assets/images/icon/moon.svg";

const LightDark = () => {
  return (
    <div>
      <div className="lightdark-switch">
        <span className="switch-btn" id="btnSwitch">
          <img src={moon} alt="light-dark-switchbtn" className="swtich-icon" />
        </span>
      </div>
    </div>
  );
};

export default LightDark;
