import React from "react";
import aboutImage from "../../assets/images/about/home3/1.png";
import authorImage from "../../assets/images/about/home3/5.png";
import Israel from "../../assets/images/about/home3/Israel.png";
import Adewumi from "../../assets/images/about/home3/Adewumi.png";

const About = () => {
  return (
    <div>
      <section className="about about--style3 padding-bottom">
        <div className="container">
          <div className="about__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-md-6">
                <div
                  className="about__thumb about__thumb--style4"
                  dataaos="fade-right"
                  dataaosduration="800"
                >
                  <div className="about__thumb-inner">
                    <div className="about__thumb-image text-center floating-content">
                      <img
                        className="dark"
                        src={aboutImage}
                        alt="about-image"
                      />
                      <div
                        className="floating-content__bottom-left floating-content__bottom-left--style3"
                        dataaos="fade-right"
                        dataaosduration="1000"
                      >
                        <div
                          className="floating-content__item floating-content__item--style4"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="floating-content__item-thum">
                            <img src={Israel} alt="author" />
                          </div>
                          <div className="floating-content__item-content">
                            <p>Oluwasegun Israel</p>
                            <span>CEO/Founder</span>
                          </div>
                        </div>
                        <div className="floating-content__item floating-content__item--style4">
                          <div className="floating-content__item-thum">
                            <img src={Adewumi} alt="author" />
                          </div>
                          <div className="floating-content__item-content">
                            <p>Adewumi Grace</p>
                            <span>General Manager</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="about__content"
                  dataaos="fade-left"
                  dataaosduration="800"
                >
                  <h2>
                    Meet<span> With Us</span>Your Trusted Partner
                    in Forex Success
                  </h2>
                  <p className="mb-0">
                    At ProTradesInsight, we are more than just a Forex trading
                    company — we are dedicated to helping you achieve financial
                    success. With years of experience, we specialize in trading
                    for individuals, providing exclusive VIP signals, and
                    offering expert tutoring in Forex. Our professional traders
                    work around the clock to ensure profitable results for our
                    clients, while our VIP signals give you the edge needed to
                    navigate the market with confidence. Whether you're new to
                    trading or looking to enhance your skills, ProTradesInsight
                    is here to guide you every step of the way.
                  </p>
                  <a
                    href="about.html"
                    className="trk-btn trk-btn--border trk-btn--primary"
                  >
                    Explore More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
