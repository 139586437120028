import React from "react";
import {
  Box,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Avatar,
  Card,
  CardContent,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Israel from "../../assets/images/traders/Israel.png";
import Adewumi from "../../assets/images/traders/Adewumi.png";
// Import slick carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProfessionalTradersSection = () => {
  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowForwardIosIcon />,
    prevArrow: <ArrowBackIosIcon />,
  };

  return (
    <div>
      {/* Reduced width for text section */}
      <Box
        sx={{
          padding: { xs: "20px", sm: "30px", md: "40px" },
          maxWidth: "800px",
          margin: "0 auto", // Center align
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          align="center"
          sx={{ marginBottom: "20px" }}
        >
          <h2>
            <span style={{ color: "#0a4fd5" }}>Meet Our</span> Professional
            Traders
          </h2>
        </Typography>

        <Typography variant="subtitle1" align="center" gutterBottom>
          Israel is the Lead Trader at ProTradesInsight, and he explains that
          the markets we trade are extremely liquid. As retail traders, we are
          small fish in a vast ocean, so understanding where the big money is
          placing their orders is crucial and gives you an edge. In a
          decentralized market, it’s difficult to know where major bets are
          being placed. However, by studying historical price action, you can
          identify where big orders were placed in the past. These are key
          levels, and it's here that you'll find a high probability of
          significant orders being placed.
        </Typography>
      </Box>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 20px",
        }}
      >
        <Box
          sx={{
            padding: "40px 20px",
            p: 4,
            width: { xs: "90%", sm: "80%", md: "900px" },
            padding: { xs: "40px 20px", sm: "60px 30px", md: "40px 20px" },
          }}
        >
          {/* Main Grid Layout */}
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="flex-start"
          >
            {/* Left Section - Text and List */}
            <Grid item xs={12} md={6}>
              {/* Checklist */}
              <List>
                {[
                  "Experts in the main financial markets",
                  "Chat 24/7 with our pro traders",
                  "Live webinars",
                  "Free and VIP Signals",
                  "Best forex signals",
                  "Full technical analysis",
                  "Every question will be answered",
                ].map((text, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Faded transparent black
                      borderRadius: "8px", // Slightly rounded corners
                      mb: 1, // Margin bottom for spacing between items
                      px: 2, // Padding left and right
                    }}
                  >
                    <ListItemIcon>
                      <CheckCircleIcon sx={{ color: "#00bfa5" }} />
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                ))}
              </List>
            </Grid>

            {/* Right Section - Swipable Testimonial Carousel */}
            <Grid item xs={12} md={6}>
              <Slider {...settings}>
                {[
                  {
                    name: "Oluwasegun Israel",
                    experience: "Lead Trader",
                    testimony:
                      "To excel in the markets, I focus on moving with their natural rhythm. Rather than resisting market trends, I embrace them, finding opportunities where the path is clear and less obstructed. By carefully observing market data and understanding the broader economic landscape, I leverage precise technical analysis to uncover the best moments to enter trades. This method allows me to stay ahead of the curve and optimize my trading success.",
                    img: Israel, // Use the imported image
                  },
                  {
                    name: "Adewumi Grace",
                    experience: "VIP Signals Manager",
                    testimony:
                      "Long-term trading success hinges on a deep understanding of market fundamentals. Without insight into what drives market movements, achieving consistent profitability is nearly impossible. My expertise lies in global macroeconomics, where I analyze how economic policies shape currency valuations to guide my trading decisions. This knowledge provides me with a strategic advantage. My approach to the markets is centered around harmony with their natural momentum.",
                    img: Adewumi, // Replace with actual image path
                  },
                ].map((trader, index) => (
                  <Card
                    key={index}
                    sx={{
                      borderRadius: "8px",
                      padding: "20px",
                      boxShadow: 3,
                      height: "400px", // 
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between", 
                    }}
                  >
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <Avatar
                          src={trader.img} // Use the correct image path
                          alt={trader.name}
                          sx={{ width: 64, height: 64, mr: 2 }}
                        />
                        <Box>
                          <Typography variant="h6" fontWeight="bold">
                            {trader.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {trader.experience}
                          </Typography>
                        </Box>
                      </Box>

                      <Typography variant="body2" color="textSecondary">
                        {trader.testimony}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Slider>
            </Grid>
          </Grid>

          {/* Button Section */}
          <Box mt={4} textAlign="center">
            <Button
              variant="contained"
              style={{ backgroundColor: "#393e4c" }}
              size="large"
            >
              Become a VIP Member
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ProfessionalTradersSection;
