import React from 'react';
import Slider from 'react-slick'; // Import the slick slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import checkIcon from '../../assets/images/icon/check.svg';
import shapeIcon1 from '../../assets/images/icon/1.png';

// Settings for the carousel
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

function OurPlans() {
  return (
    <div>
      <section className="pricing padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">Our <span>pricings</span> plan</h2>
          <p>We offer the best pricings around - from installations to repairs, maintenance, and more!</p>
        </div>
        <div className="container">
          <Slider {...sliderSettings}> {/* Slick Carousel */}
            {/* Basic Plan */}
            <div>
              <div className="pricing__item">
                <div className="pricing__item-inner">
                  <div className="pricing__item-content">
                    <div className="pricing__item-top">
                      <h6 className="mb-15">Basic</h6>
                      <h3 className="mb-25">$99/<span>Monthly</span></h3>
                    </div>
                    <div className="pricing__item-middle">
                      <ul className="pricing__list">
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Weekly online meeting
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Unlimited learning access
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          24/7 technical support
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Personal training
                        </li>
                      </ul>
                    </div>
                    <div className="pricing__item-bottom">
                      <a href="signup.html" className="trk-btn trk-btn--outline">Choose Plan</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Standard Plan */}
            <div>
              <div className="pricing__item active">
                <div className="pricing__item-inner">
                  <div className="pricing__item-content">
                    <div className="pricing__item-top">
                      <h6 className="mb-15">Standard</h6>
                      <h3 className="mb-25">$149/<span>Monthly</span></h3>
                    </div>
                    <div className="pricing__item-middle">
                      <ul className="pricing__list">
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Weekly online meeting
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Unlimited learning access
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          24/7 technical support
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Business analysis
                        </li>
                      </ul>
                    </div>
                    <div className="pricing__item-bottom">
                      <a href="signup.html" className="trk-btn trk-btn--outline active">Choose Plan</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Premium Plan */}
            <div>
              <div className="pricing__item">
                <div className="pricing__item-inner">
                  <div className="pricing__item-content">
                    <div className="pricing__item-top">
                      <h6 className="mb-15">Premium</h6>
                      <h3 className="mb-25">$199/<span>Monthly</span></h3>
                    </div>
                    <div className="pricing__item-middle">
                      <ul className="pricing__list">
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          With all standard features
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Unlimited learning access
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          24/7 technical support
                        </li>
                        <li className="pricing__list-item">
                          <img src={checkIcon} alt="check" className="dark" />
                          Personal training
                        </li>
                      </ul>
                    </div>
                    <div className="pricing__item-bottom">
                      <a href="signup.html" className="trk-btn trk-btn--outline">Choose Plan</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* Pricing shapes */}
        <div className="pricing__shape">
          <span className="pricing__shape-item pricing__shape-item--1"><span></span></span>
          <span className="pricing__shape-item pricing__shape-item--2">
            <img src={shapeIcon1} alt="shape-icon" />
          </span>
        </div>
      </section>
    </div>
  );
}

export default OurPlans;
