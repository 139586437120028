import React from 'react';
import bannerImage from '../../assets/images/banner/home4/1.png';
import shapeIcon1 from '../../assets/images/banner/home1/4.png';
import shapeIcon2 from '../../assets/images/banner/home4/2.png';

const Banner = () => {
  return (
    <div>
      <section 
        className="banner banner--style4 bg--cover"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="banner__wrapper">
            <div className="row justify-content-center">
              <div className="col-md-10 justify-content-center">
                <div className="banner__content" dataaos="fade-up" dataaosduration="800">
                  <h2 style={{color: "#ffffff"}}>Start Winning with ProTradesInsight</h2>
                  <p>
                  Expert Forex & Crypto Signals Instantly.
                  </p>
                  <div className="banner__content-btn btn-group justify-content-center">
                    <a href="signin.html" className="trk-btn trk-btn--primary trk-btn--arrow">Trading Now</a>
                    <a href="https://www.youtube.com/watch?v=MHhIzIgFgJo&ab_channel=NoCopyrightFootages" className="playbtn" datafslightbox="">
                      <span className="playbtn__thumb"><i className="fa-solid fa-play"></i></span>
                      <span className="playbtn__content">Watch Video</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner__shape">
          <span className="banner__shape-item banner__shape-item--1">
            <img src={shapeIcon1} alt="shape icon" />
          </span>
          <span className="banner__shape-item banner__shape-item--5">
            <img src={shapeIcon2} alt="shape icon" />
          </span>
        </div>
      </section>
    </div>
  );
}

export default Banner;
