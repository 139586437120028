import React, { useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import derivImage from "../../assets/images/partners/deriv.png";
import hantecImage from "../../assets/images/partners/hantec.png";

const BrokerSection = () => {
  const [selectedBroker, setSelectedBroker] = useState("deriv");

  // Broker information based on the clicked image
  const brokerInfo = {
    deriv: {
      name: "Deriv",
      description:
        "Deriv is a leading trading platform offering a wide range of assets and CFDs, with user-friendly UI and superior execution.",
    },
    hantec: {
      name: "Hantec Markets",
      description:
        "Hantec Markets provides low spreads, excellent customer service, and advanced trading tools for traders of all levels.",
    },
    another: {
      name: "Another Broker",
      description:
        "This is another broker that provides a great platform with low fees and excellent liquidity for traders.",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "40px 20px",
      }}
    >
      <Box
        sx={{
          p: 4,
          width: { xs: "90%", sm: "80%", md: "1200px" },
          padding: { xs: "40px 20px", sm: "60px 30px", md: "40px 20px" },
        }}
      >
        {/* Left Section */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              align="left" // Align to the left
              gutterBottom
            >
              OR
            </Typography>
            <Typography
              variant="h4"
              fontWeight="bold"
              align="left" // Align to the left
              gutterBottom
            >
              Become A Professional Crypto Trader!
            </Typography>
            <Typography variant="body1" align="left"> {/* Align to the left */}
              Get LIFETIME access to our VIP forex signals for FREE! Register
              and deposit the minimum 250 USD with our chosen regulated FX/CFD
              broker below and receive LIFETIME access to our VIP Crypto Signals
              for FREE!
            </Typography>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#f9f9f9",
                borderRadius: "8px",
                padding: "20px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Broker Logos */}
              <Grid container spacing={2} justifyContent="center">
                {/* Deriv */}
                <Grid item xs={4}>
                  <Box
                    onClick={() => setSelectedBroker("deriv")}
                    sx={{
                      backgroundColor:
                        selectedBroker === "deriv" ? "#e0f7fa" : "transparent",
                      padding: "20px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={derivImage}
                      alt="Deriv logo"
                      sx={{
                        width: { xs: "100%", sm: "80%" }, // Expand on mobile views
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Grid>

                {/* Hantec Markets */}
                <Grid item xs={4}>
                  <Box
                    onClick={() => setSelectedBroker("hantec")}
                    sx={{
                      backgroundColor:
                        selectedBroker === "hantec" ? "#e0f7fa" : "transparent",
                      padding: "20px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={hantecImage}
                      alt="Hantec logo"
                      sx={{
                        width: { xs: "100%", sm: "80%" }, // Expand on mobile views
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Grid>

                {/* Another Broker */}
                <Grid item xs={4}>
                  <Box
                    onClick={() => setSelectedBroker("another")}
                    sx={{
                      backgroundColor:
                        selectedBroker === "another"
                          ? "#e0f7fa"
                          : "transparent",
                      padding: "20px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={derivImage}
                      alt="Another logo"
                      sx={{
                        width: { xs: "100%", sm: "80%" }, // Expand on mobile views
                        margin: "0 auto",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* Broker Info */}
              <Typography variant="body1" fontWeight="bold" sx={{ mt: 3 }} align="left">
                {brokerInfo[selectedBroker].name}
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }} align="left">
                {brokerInfo[selectedBroker].description}
              </Typography>

              {/* Choose Broker Button */}
              <Button variant="contained" sx={{backgroundColor: '#192231'}} fullWidth>
                Choose Broker
              </Button>

              {/* Disclaimer Text */}
              <Typography
                variant="caption"
                display="block"
                color="textSecondary"
                sx={{ mt: 2 }}
                align="left" // Align to the left
              >
                Don’t invest unless you’re prepared to lose all the money you
                invest. This is a high-risk investment, and you are unlikely to
                be protected if something goes wrong.{" "}
                <a href="/learn-more">Take 2 minutes to learn more</a>.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BrokerSection;
