import React from 'react';
import logoDark from '../../assets/images/logo/logo-white.png';
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <header className="header-section header-section--style4">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <a href="index.html">
                  <img src={logoDark} alt="logo" />
                </a>
              </div>
              <div className="menu-area">
                <ul className="menu menu--style2">
                <li>
                    {/* <a href="#">Home</a> */}
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="#">Forex</a>
                    <ul className="submenu">
                      <li><Link to="/forex-signal">Forex Signals</Link></li>
                      <li><a href="#">Forex Brokers</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">crypto</a>
                    <ul className="submenu">
                      <li><Link to="/crypto-signal">Crypto Signals</Link></li>
                      <li><a href="#">Crypto Trading Mastery</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Services</a>
                    <ul className="submenu">
                      <li><a href="services.html">Services</a></li>
                      <li><a href="service-details.html">Services Details</a></li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a href="signup.html" className="trk-btn trk-btn--border trk-btn--primary">
                      <span>LOG IN</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div className="header-bar d-lg-none header-bar--style2">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
