import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import derivImage from '../../assets/images/partners/deriv.png';
import hantecImage from '../../assets/images/partners/hantec.png';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 2000, // Fast speed for continuous scroll
  slidesToShow: 6, // Default for large screens
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  autoplay: false, // Disable autoplay to control with custom logic
  cssEase: 'linear', // Smooth, continuous scroll
  pauseOnHover: false, // Disable pausing on hover
  draggable: false, // Disable dragging to ensure continuous scroll
  responsive: [
    {
      breakpoint: 1024, // Tablet and small desktops
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // Mobile devices
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480, // Smaller mobile devices
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const PartnerCarousel = () => {
  const sliderRef = useRef(null);
  const [scrollDirection, setScrollDirection] = useState('next');

  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        if (scrollDirection === 'next') {
          sliderRef.current.slickNext(); // Move to the next slide
        } else {
          sliderRef.current.slickPrev(); // Move to the previous slide
        }
        // Alternate direction for the next interval
        setScrollDirection((prevDirection) => (prevDirection === 'next' ? 'prev' : 'next'));
      }
    }, 2000); // Adjust the interval to control the scroll speed

    return () => {
      clearInterval(interval);
    };
  }, [scrollDirection]);

  return (
    <div className="partner-carousel-container" style={{ backgroundColor: '#1a1b24', padding: '10px' }}>
      <Slider {...sliderSettings} ref={sliderRef}>
        {/* Partner Items */}
        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={derivImage} alt="Deriv" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.01 USD CRYPTO 0.05</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>

        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={hantecImage} alt="Hantec" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.25 USD CRYPTO 0.03</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>
        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={derivImage} alt="Deriv" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.01 USD CRYPTO 0.05</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>

        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={hantecImage} alt="Hantec" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.25 USD CRYPTO 0.03</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>
        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={derivImage} alt="Deriv" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.01 USD CRYPTO 0.05</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>

        <div className="partner-item" style={styles.partnerItem}>
          <div style={styles.logoContainer}>
            <img src={hantecImage} alt="Hantec" style={styles.logo} />
          </div>
          <div style={styles.infoContainer}>
            <p style={styles.infoText}>FOREX 0.25 USD CRYPTO 0.03</p>
            <button className="trade-btn" style={styles.tradeButton}>TRADE NOW</button>
          </div>
        </div>

        {/* Repeat partner items as needed */}
      </Slider>
    </div>
  );
};

const styles = {
  partnerItem: {
    display: 'flex',
    flexDirection: 'column', // Stack logo and info vertically
    alignItems: 'center', // Center items horizontally
    backgroundColor: '#2a2b36',
    borderRadius: '10px',
    padding: '10px',
    margin: '0 20px', // Horizontal margin for spacing
    color: 'white',
    width: '150px', // Fixed width for each item
    boxSizing: 'border-box', // Include padding and border in the width
  },
  logoContainer: {
    display: 'flex', // Center logo horizontally
    justifyContent: 'center',
    width: '100%', // Ensure it uses the full width of the parent
    marginBottom: '10px', // Space between logo and text
  },
  logo: {
    width: '150px', // Fixed width for the logo
    height: '50px', // Maintain aspect ratio
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center text and button horizontally
    width: '100%', // Ensure it uses the full width of the parent
  },
  infoText: {
    marginBottom: '5px', // Reduced bottom margin for text
    fontSize: '10px', // Reduced font size
    color: '#ffffff',
    textAlign: 'center', // Center the text
  },
  tradeButton: {
    backgroundColor: '#343842',
    color: 'white',
    border: 'none',
    padding: '5px', // Reduced padding for smaller button size
    fontSize: '10px', // Reduced font size
    cursor: 'pointer',
    borderRadius: '3px', // Smaller border-radius for the button
    textAlign: 'center', // Center the text inside the button
    outline: 'none',
    height: '30px',
    fontWeight: 'bold',
    width: '200px', // Adjust width for the button
  },
};

export default PartnerCarousel;
