import React from "react";
import service1 from "../../assets/images/service/1.png";
import service2 from "../../assets/images/service/2.png";
import service3 from "../../assets/images/service/3.png";
import service4 from "../../assets/images/service/4.png";
import service5 from "../../assets/images/service/5.png";
import service6 from "../../assets/images/service/6.png";
import shape1 from "../../assets/images/icon/shape/1.png";
import shape2 from "../../assets/images/icon/shape/2.png";

const Services = () => {
  return (
    <div>
      <section className="service padding-top padding-bottom bg-color-7">
        <div className="section-header section-header--max50">
          <h2 className="mb-10 mt-minus-5">
            <span className="style2">services </span>We offer
          </h2>
          <p>
            We offer the best services around - from installations to repairs,
            maintenance, and more!
          </p>
        </div>
        <div className="container">
          <div className="service__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="800"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service1} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          VIP Forex Signals Subscription
                        </a>
                      </h5>
                      <p className="mb-0">
                        We provide premium real-time trading signals to help you
                        make informed decisions in the forex market, with entry
                        and exit points.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="1000"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service2} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          Financial Advisory
                        </a>
                      </h5>
                      <p className="mb-0">
                        A comprehensive tutoring program where you can learn
                        forex trading strategies, technical analysis, and risk
                        management from beginner to advanced levels.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="1200"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service3} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          Management
                        </a>
                      </h5>
                      <p className="mb-0">
                        There, it's me, your friendly neighborhood reporter's
                        news analyst processes and improving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="800"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service4} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          Managed Forex Accounts
                        </a>
                      </h5>
                      <p className="mb-0">
                        Offer a hands-on approach where our expert traders
                        manage and trade on behalf of you, ensuring consistent
                        returns with minimal effort from you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="1000"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service5} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          Crypto Signals & Analysis
                        </a>
                      </h5>
                      <p className="mb-0">
                        We provide insights and real-time trading signals for the
                        cryptocurrency market, helping clients diversify and
                        trade digital currencies effectively.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item service__item--style2"
                  dataaos="fade-up"
                  dataaosduration="1200"
                >
                  <div className="service__item-inner text-center">
                    <div className="service__item-thumb mb-30">
                      <img className="dark" src={service6} alt="service-icon" />
                    </div>
                    <div className="service__item-content">
                      <h5>
                        <a
                          className="stretched-link"
                          href="service-details.html"
                        >
                          Marketing Consulting
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey! Just wanted to let you know that the price
                        notification module processes is now live!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service__shape">
          <span className="service__shape-item service__shape-item--2">
            <img src={shape1} alt="shape-icon" />
          </span>
          <span className="service__shape-item service__shape-item--3">
            <img src={shape2} alt="shape-icon" />
          </span>
        </div>
      </section>
    </div>
  );
};

export default Services;
