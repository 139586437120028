import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import logoDark from "../assets/images/logo/logo-white.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState({});

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClick = (index) => {
    setOpenSubmenu((prevOpenSubmenu) => ({
      ...prevOpenSubmenu,
      [index]: !prevOpenSubmenu[index],
    }));
  };

  const menuItems = [
    { text: "Home", link: "/" },
    {
      text: "Forex",
      submenu: [
        { text: "Forex Signals", link: "/forex-signal" },
        { text: "Forex Brokers", link: "/forex-brokers" },
      ],
    },
    {
      text: "Crypto",
      submenu: [
        { text: "Crypto Signals", link: "/crypto-signal" },
        { text: "Crypto Trading Mastery", link: "/crypto-trading-mastery" },
      ],
    },
    {
      text: "Services",
      submenu: [
        { text: "Services", link: "/services" },
        { text: "Services Details", link: "/service-details" },
      ],
    },
    { text: "About", link: "/about" },
    { text: "Contact Us", link: "/contact" },
  ];

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem
          button
          onClick={() => item.submenu && handleClick(index)}
          component={item.submenu ? "div" : Link}
          to={item.submenu ? undefined : item.link}
          aria-haspopup={item.submenu ? "true" : undefined}
          aria-expanded={item.submenu ? openSubmenu[index] : undefined}
        >
          <ListItemText primary={item.text} />
          {item.submenu ? (
            openSubmenu[index] ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </ListItem>
        {item.submenu && (
          <Collapse in={openSubmenu[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.submenu.map((subItem, subIndex) => (
                <ListItem
                  key={`${index}-${subIndex}`}
                  button
                  component={Link}
                  to={subItem.link}
                  style={{ paddingLeft: 32 }}
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText primary={subItem.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: "#000000" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            style={{ fontSize: "2rem" }}
          >
            <MenuIcon style={{ fontSize: "2.5rem" }} />
          </IconButton>
          <img src={logoDark} alt="logo" style={{ height: 30 }} />
          <div style={{ flexGrow: 1 }} />
          <div
            className="header-btn"
            style={{ marginTop: "15px", marginBottom: "15px" }}
          >
            <a
              href="signup.html"
              className="trk-btn trk-btn--border trk-btn--primary"
            >
              <span>LOG IN</span>
            </a>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={(event) => {
            if (!event.target.closest(".MuiListItem-root")) {
              toggleDrawer(false)(event);
            }
          }}
          onKeyDown={(event) => {
            if (!event.target.closest(".MuiListItem-root")) {
              toggleDrawer(false)(event);
            }
          }}
          style={{ width: 250 }}
        >
          <List>{renderMenuItems(menuItems)}</List>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
