import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram'; // MUI icon

const ServiceInfo = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: '40px 20px' }}>
      <Box
        sx={{
          backgroundColor: '#192231',
          padding: { xs: '40px 20px', sm: '60px 30px', md: '80px 60px' }, // responsive padding
          color: '#ffffff',
          textAlign: 'left',
          marginTop: '40px',
          marginBottom: '20px',
          width: { xs: '90%', sm: '80%', md: "1000px" }, // responsive width
          borderRadius: "30px",
          boxSizing: 'border-box', // makes sure padding is accounted within width
        }}
      >
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <Typography component="div">
              ✓ Service for copy trading. Our Algo automatically opens and closes trades.
            </Typography>
            <Typography component="div" style={{ marginTop: "10px" }}>
              ✓ The PTI Algo provides highly profitable signals with minimal risk.
            </Typography>
            <Typography component="div" style={{ marginTop: "10px" }}>
              ✓ 24/7 cryptocurrency trading. While you sleep, we trade.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Typography component="div">
              ✓ Up to 70 trades per month. There are more than 5 pairs available.
            </Typography>
            <Typography component="div" style={{ marginTop: "10px" }}>
              ✓ Real-time alerts, all via Telegram.
            </Typography>
            <Typography component="div" style={{ marginTop: "10px" }}>
              ✓ Monthly subscriptions begin at £58.
            </Typography>
          </Grid>
        </Grid>

        {/* Button section */}
        <Box
          mt={4}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={3}
          sx={{
            flexDirection: { xs: 'column', sm: 'row' }, // column on mobile, row on larger screens
            width: '100%', // ensures buttons take full width on small screens
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#393e4c",
              height: "60px",
              width: { xs: '100%', sm: 'auto' }, // full width on mobile, auto on larger screens
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'skyblue', // sky blue background for the icon
                borderRadius: '50%',
                padding: '8px',
                marginRight: '8px', // spacing between icon and text
              }}
            >
              <TelegramIcon />
            </Box>
            PTI Algo
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#393e4c",
              height: "60px",
              width: { xs: '100%', sm: 'auto' }, // full width on mobile, auto on larger screens
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'skyblue', // sky blue background for the icon
                borderRadius: '50%',
                padding: '8px',
                marginRight: '8px', // spacing between icon and text
              }}
            >
              <TelegramIcon />
            </Box>
            Telegram
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#393e4c",
              height: "60px",
              width: { xs: '100%', sm: 'auto' }, // full width on mobile, auto on larger screens
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'skyblue', // sky blue background for the icon
                borderRadius: '50%',
                padding: '8px',
                marginRight: '8px', // spacing between icon and text
              }}
            >
              <TelegramIcon />
            </Box>
            Results
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default ServiceInfo;
