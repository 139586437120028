import React from 'react';
import icon1 from '../../assets/images/banner/home4/icon/1.png';
import icon2 from '../../assets/images/banner/home4/icon/2.png';
import icon3 from '../../assets/images/banner/home4/icon/3.png';
import icon4 from '../../assets/images/banner/home4/icon/4.png';

const CounterPadding = () => {
  return (
    <div>
      <div className="counter padding-bottom">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row g-3">
                <div className="col-sm-6 col-lg-3">
                  <div className="counter__item" dataaos="fade-up" dataaosduration="800">
                    <div className="counter__item-inner">
                      <img src={icon1} alt="icon" className="dark" />
                      <h3>
                        <span className="purecounter" datapurecounterstart="880" datapurecounterend="900">82%</span> +
                      </h3>
                      <p>Stay with us</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter__item" dataaos="fade-up" dataaosduration="1000">
                    <div className="counter__item-inner">
                      <img src={icon2} alt="icon" className="dark" />
                      <h3>
                        <span className="purecounter" datapurecounterstarttart="0" datapurecounterend="10">3k</span>+ 
                      </h3>
                      <p>Signals sent</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter__item" dataaos="fade-up" dataaosduration="1000">
                    <div className="counter__item-inner">
                      <img src={icon3} alt="icon" className="dark" />
                      <h3>
                        <span className="purecounter" datapurecounterstarttart="70" datapurecounterend="80">8</span>+ Years
                      </h3>
                      <p>Experience</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="counter__item" dataaos="fade-up" dataaosduration="1200">
                    <div className="counter__item-inner">
                      <img src={icon4} alt="icon" className="dark" />
                      <h3>
                        <span className="purecounter" datapurecounterstarttart="0" datapurecounterend="12">12</span>K+
                      </h3>
                      <p>Members</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CounterPadding;
